import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

const SeeAllNews = () => (
  <Link to="/company/releases">
    <div className="flex items-center">
      <StaticImage src="../../../../images/arrow-left.svg" className="w-4 md:w-8" alt="arrow back" />
      <div className="pl-4 font-bold md:pl-8 text-blue">See all New and Press Releases</div>
    </div>
  </Link>
);

const TrustleRaises6mSeedFundingRound = () => {
  const releaseTitle = 'Trustle Raises $6M Seed Round to Revolutionize Access Management';
  return (
    <Layout
      seo={
        <Seo
          title="Newsroom | Trustle Raises $6M Seed Round to Revolutionize Access Management"
          description=" Trustle announced a $6 million seed
              funding round led by Glasswing Ventures, with participation from FUSE, Correlation Ventures, and Capital
              Technology Ventures."
        />
      }
    >
      <section className="pb-16 bg-gradient-to-b from-gray-200 via-white to-gray-200 xl:pb-64">
        <div className="flex flex-col py-16 lg:pb-32 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
          <SectionIntro
            isPageHeader
            hero="Company"
            title="Newsroom"
            description={`Stay up-to-date with everything that is happening with the company.</br>`}
          />

          <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
            <div className="py-16">
              <SeeAllNews />
            </div>
            <div className="mx-8">
              <div className="mb-8 text-xl font-bold text-left md:text-2xl">{releaseTitle}</div>
              <p className="text-lg text-left">
                California-based startup looks to secure the cloud environment by expanding the development of its cloud
                native access management solution with more AI-driven capabilities WALNUT CREEK, CA. – April 4, 2023 —
                Trustle, provider of innovative cloud access management solutions, today announced a $6 million seed
                funding round led by Glasswing Ventures, with participation from FUSE, Correlation Ventures, and Capital
                Technology Ventures. Founded in 2019, Trustle’s mission is to revolutionize the management of
                access-at-risk. The company will use this funding to expand product development, marketing research and
                sales resources.<br></br>
                <br></br> According to a 2022 report from Identity Defined Security Alliance, 84% of respondents
                experienced an identity-related breach in the last year, with 78% citing a direct business impact as a
                result. Trustle is helping organizations address identity and access-based attacks by enabling them to
                follow the principle of least privilege and just-in-time access for securing sensitive and confidential
                data. <br></br>
                <br></br> Specifically, Trustle enables deep integration into a company’s critical cloud resources,
                including Azure, Okta, and Google Workspaces, and makes it simple for citizen developers to automate
                secure access control and compliance reviews across systems. Most recently, Trustle added support for
                Tableau and expanded support for GitHub.<br></br>
                <br></br> “The mechanisms to manage access differ across cloud resources, making it incredibly difficult
                for teams to wrap their arms around who has access to what, creating risk for enterprises. Trustle
                packages all of these nuances into one streamlined platform that provides dynamic permissions and access
                management for citizen developers," said Emiliano Berenbaum, CEO, Trustle. "We are thrilled to have the
                support of each of our investors and look forward to growing our presence in the market." <br></br>
                <br></br> With Trustle, teams can automatically grant and rescind access to multiple resources on a
                user-by-user basis while offering a holistic overview of all connected systems. The Trustle platform
                also allows companies to categorize employees, contractors, systems, and customers without storing this
                information in an HRM (human resource management) system. To support audit and regulatory requirements,
                Trustle provides approval workflows. <br></br>
                <br></br> To further enhance the user experience, Trustle’s upcoming product roadmap includes new
                Trustle Pacs, allowing users to cluster permissions from multiple systems such as AWS or Github. In
                practice, this will enable the head of an account the ability to grant access to specific individuals
                rather than an HR manager or system administrator, allowing for enhanced efficiency. Additionally,
                Trustle will calculate micro and macro risk levels for individual employees, contractors, customers, and
                connected systems based on misconfigured settings and access. <br></br>
                <br></br> "As identity and access management practitioners themselves, Trustle’s leadership team has
                seen firsthand the seemingly never-ending stream of access requests that a business generates daily,”
                said Rick Grinnell, Glasswing Ventures Founder and Managing Partner. “They have witnessed the pervasive
                challenges associated with employee access, and how contractors, systems and customer access are also
                being improperly managed and tracked. Glasswing is impressed by Trustle’s AI-driven solutions to address
                access management in the cloud, and we believe the company is well-positioned for success in this
                market." <br></br>
                <br></br> "Cloud systems are both the most critical and often least protected assets, mainly because
                current solutions lack the depth and alignment with modern operating models. Trustle recognized a
                significant market need to improve on cloud platforms and operations management, and has already taken
                significant steps to present a meaningful solution to the market. FUSE is pleased to invest in Trustle
                and be part of their journey to revolutionize access management,” added Kellan Carter, partner at FUSE.
                <br></br>
                <br></br>
                Trustle will be at the 2023 RSA Conference in San Francisco from April 24-27. To meet the Trustle team
                and learn more about the company’s access management technology during the event, please get in touch.{' '}
                <br></br>
                <br></br> About Trustle Trustle simplifies needs-based access control and enables a more secure and
                efficient cloud environment. With Trustle, system owners can create a comprehensive catalog of
                permissions and policies that allow front-line users to request access or be granted temporary,
                just-in-time, or until-revoked access. Trustle helps developers, citizen developers, teams and
                organizations manage access-at-risk within minutes, establish new strategies and structure to protect
                business data within days, and improve and accelerate compliance. Learn more about Trustle at
                https://www.trustle.com/. <br></br>
                <br></br> Media Contact
                <br></br> Dana Segan<br></br> LaunchTech Communications<br></br> 732-997-9881<br></br>{' '}
                <u>dsegan@golaunchtech.com</u>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TrustleRaises6mSeedFundingRound;
